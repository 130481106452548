exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog/article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-landscape-article-js": () => import("./../../../src/templates/blog/landscapeArticle.js" /* webpackChunkName: "component---src-templates-blog-landscape-article-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-digitalexportmanager-js": () => import("./../../../src/templates/landing/digitalexportmanager.js" /* webpackChunkName: "component---src-templates-landing-digitalexportmanager-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-landing-ricercheanalisimercato-js": () => import("./../../../src/templates/landing/ricercheanalisimercato.js" /* webpackChunkName: "component---src-templates-landing-ricercheanalisimercato-js" */),
  "component---src-templates-landing-tumulti-index-js": () => import("./../../../src/templates/landing/tumulti/index.js" /* webpackChunkName: "component---src-templates-landing-tumulti-index-js" */),
  "component---src-templates-landing-tumulti-tumulti-issue-js": () => import("./../../../src/templates/landing/tumulti/tumultiIssue.js" /* webpackChunkName: "component---src-templates-landing-tumulti-tumulti-issue-js" */),
  "component---src-templates-landscape-page-js": () => import("./../../../src/templates/landscapePage.js" /* webpackChunkName: "component---src-templates-landscape-page-js" */),
  "component---src-templates-member-js": () => import("./../../../src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-index-js": () => import("./../../../src/templates/portfolio/index.js" /* webpackChunkName: "component---src-templates-portfolio-index-js" */),
  "component---src-templates-portfolio-portfolio-collection-js": () => import("./../../../src/templates/portfolio/portfolioCollection.js" /* webpackChunkName: "component---src-templates-portfolio-portfolio-collection-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-product-product-category-js": () => import("./../../../src/templates/product/productCategory.js" /* webpackChunkName: "component---src-templates-product-product-category-js" */)
}

